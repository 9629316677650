import React, { useState } from "react";
import logo from "../assets/knackbay_logo.png";
import { FaXmark, FaBars } from "react-icons/fa6";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import { Link,  } from "react-router-dom";

import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const navItem = [
    { link: "Home", path: "home" },
    { link: "About Us", path: "about" },
    { link: "Services", path: "services" },
    { link: "Contact Us", path: "Contact " },
  ];

  return (
    <>
      <nav className="bg-white opacity-100 md:px-14 shadow-custom p-4 max-w-screen-2xl mx-auto text-blue fixed top-0 right-0 left-0">
        <div className="text-lg container mx-auto  font-medium">
          <div className="flex items-center justify-between">
            <Link to="/">
              <img src={logo} alt="" className="w-24" />
            </Link>
            <div className="md:flex hidden">
              <ul className="flex space-x-28 ml-auto mr-10 font-quicksand font-semibold">
                {navItem.map(({ link, path }) => (
                  <li key={path} className="inline-block">
                    <ScrollLink
                      spy={true}
                      smooth={true}
                      offset={-100}
                      to={path}
                      className="hover:text-gray-300 cursor-pointer"
                    >
                      {link}
                    </ScrollLink>
                  </li>
                ))}
              </ul>
            </div>
            <div className="md:hidden">
              <button
                onClick={toggleMenu}
                className="text-white focus:outline-none focus:text-gray"
              >
                {isMenuOpen ? (
                  <FaXmark className="w-6 h-6 text-blue" />
                ) : (
                  <FaBars className="w-6 h-6 text-blue" />
                )}
              </button>
            </div>
          </div>
        </div>
      </nav>
      <div
        className={`space-y-4 px-4 pt-28 mb-5 bg-blue-light ${
          isMenuOpen ? "block fixed top-4 right-0 left-0" : "hidden"
        }`}
      >
        {navItem.map(({ link, path }) => (
          <Link
            spy={true}
            smooth={true}
            offset={-100}
            to={path}
            className="block hover:text-gray cursor-pointer pl-16"
          >
            {link}
          </Link>
        ))}
      </div>
    </>
  );
};

export default Navbar;

import React from "react";
import knackbay from "../assets/Knackbay (1).png";
import { FaInstagramSquare, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-blue md:px-14 p-4 max-w-screen-2xl mx-auto text-white" id="Contact">
      <div className="my-9 flex flex-col md:flex-row gap-10">
        <div className="md:w-2/6 space-y-8">
          <a href="/" className="text-2xl font font-semibold flex items-center space-x-3 text-royal-blue">
            <img src={knackbay} alt="" className="w-80 h-15 inline-block item-center" />
          </a>
          <div className="flex items-center justify-center space-x-6 mt-4">
            <h1 className="font-quicksand font-bold">Follow Us</h1>
            <Link to="https://www.linkedin.com/company/knackbay/mycompany/">
              <FaLinkedin size={30} />
            </Link>
            <Link to="https://www.instagram.com/knackbay_/">
              <FaInstagramSquare size={30} />
            </Link>
          </div>
        </div>
        <div className="md:w-4/6 md:ml-10 mt-6 md:mt-0">
          {/* <h4 className="text-xl">Quick Links</h4> */}
          <ul className="mt-4 space-y-2">
            <li>
              <a href="/" className="block hover:text-gray">Home</a>
            </li>
            <li>
              <a href="/" className="block hover:text-gray">About Us</a>
            </li>
            <li>
              <a href="/" className="block hover:text-gray">Services</a>
            </li>
            <li>
              <a href="/" className="block hover:text-gray">Contact Us</a>
              <ul className="mt-2 space-y-1">
                <li className="hover:text-gray">+91 9025243272</li>
                <li className="hover:text-gray">knackbay@gmail.com</li>
                <li className="hover:text-gray">
                  620/6, 1st Floor, Hari Tyre Building,
                  <h3>Katpadi, Vellore, Tamil Nadu</h3>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className="flex justify-between mt-4">
        <p>@ Knack Bay 2021 --- 2024. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import web from "../assets/web.jpg";
import app from "../assets/app.jpg";
import ui from "../assets/UI_Ux.jpg";
import cus from "../assets/cus_sof.jpg";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const services = [
  {
    link: "/Webpage",
    image: web,
    title: "Web Development",
    description:
      "Combining creativity, functionality, and technical expertise to deliver impactful websites.",
  },
  {
    link: "/UiUx",
    image: ui,
    title: "UI/UX Design",
    description:
      "Creating intuitive and visually appealing interfaces for an enhanced user experience.",
  },
  {
    link: "/App",
    image: app,
    title: "App Development",
    description:
      "Bringing innovative solutions to life, ensuring seamless functionality and a delightful user experience.",
  },
  {
    link: "/App",
    image: cus,
    title: "Software Customisation",
    description:
      "Bringing innovative solutions to life, ensuring seamless functionality and a delightful user experience.",
  },
];

const WebDevelopmentApp = () => {
  return (
    <div className="  mx-auto mt-16 text-center " id="services">
      <h2 className="text-4xl font-bold mb-8 font-quicksand text-royal-blue">Our Services</h2>
      <motion.div
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 40 }}
        transition={{
          type: "tween",
          duration: 3,
          ease: [0.25, 0.25, 0.25, 0.75],
        }}
        className="flex flex-col md:flex-row-start justify-between items-center gap-10 w-6/6 p-4"
      >
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4  gap-8  ">
          {services.map((service, index) => (
            <Link to={service.link} key={index}>
              <div className="bg-white rounded-lg  overflow-hidden shadow-custom transform hover:translate-y-4 transition-all duration-300 max-w-sm  mx-auto">
                <img
                  src={service.image}
                  alt={service.title}
                  className="w-full p-4 h-58 object-cover"
                />
                <div className="p-4">
                  <h2 className="text-xl font-semibold mb-2">
                    {service.title}
                  </h2>
                  <p className="text-gray text-sm">{service.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </motion.div>
      <div
        className="text-4xl mb-5 pt-7 font-inter font-bold text-royal-blue mt-4"
      >
        Our Client{" "}
      </div>
    </div>
  );
};

export default WebDevelopmentApp;
